import React, { Component } from 'react';
import useEffect from 'react';
import axios from 'axios';
import { format } from "date-fns";
import BookingForm1 from './BookingFormsInnerComponents/BookingForm1';
import BookingForm2 from "./BookingFormsInnerComponents/BookingForm2";
import BookingForm3 from "./BookingFormsInnerComponents/BookingForm3";
// import BookingFormPayment from "./BookingFormsInnerComponents/BookingFormPayment";
// import FeaturedPayment from './BookingFormsInnerComponents/featuredPayment.js';
// import InjectedCheckoutForm from '../common/paymentForm';
import { BASE_URL } from './../projectString';
// import Moment from 'react-moment';
// import Moment from 'moment-timezone';

import { ToastContainer, toast } from 'react-toastify';

class BookingPackage extends React.Component {
  constructor() {
    super();
    this.state = {
      nextbuttonstate: 1,
      personcount: 1,
      hours: 1,
      instructon: '',
      price: localStorage.getItem('totalAmount'),
      offer_price: localStorage.getItem('totalAmount'),
      coupen_price: '',
      promocodes: '',
      apromocode_name: '',
      promomessage: '',
      pay_type: true,
      service_name: '',
      booking_date: '',
      booking_time: '',
      email: '',
      password: '',
      address: '',
      phone: '',
      name: '',
      user_id: localStorage.getItem('user_id'),
      user_name: localStorage.getItem('user_name'),
      errorPersoncount: '',
      errorHours: '',
      errorBookingTime: '',


    };
    this.get_package();
    this.getpromocode();
  }

  parentPaybycash = (childData) => {
    if (childData == 1) {
      // login
    }

  }



  handleCallback = (childData) => {
    let errorFlag = 0;

    if (childData == 3) {
      if (this.state.personcount === null || this.state.personcount === '') {
        this.setState({
          errorPersoncount: '* Please select helpers',

        });
        errorFlag = 1;
      }
      if (this.state.hours === null || this.state.hours === '') {
        this.setState({
          errorHours: '* Please select required hours',
          errorFlag: 1
        });
        errorFlag = 1;
      }
      if (this.state.booking_time === null || this.state.booking_time === '') {
        this.setState({
          errorBookingTime: '* Please select starting time',
        });
        errorFlag = 1;
      }
    }

    if (errorFlag == 0) {
      // console.log(errorFlag,"reaching here")
      this.setState({ nextbuttonstate: childData })
    }
  }



  set_booking_date = (childData) => {
    let currentTimestamp = Date.now()
    let date = new Intl.DateTimeFormat('en-US', { month: '2-digit', year: 'numeric', day: '2-digit' }).format(childData)


    this.setState({ booking_date: date })
    localStorage.setItem('date', date);
  }
  set_booking_time = (childData) => {
    this.setState({ booking_time: childData })
    localStorage.setItem('starting_hours', childData);
  }
  handleInstCallback = (childData) => {
    this.setState({ instructon: childData })
    localStorage.setItem('instruction', childData);
  }
  handle_email = (childData) => {
    this.setState({ email: childData })
  }
  handle_pass = (childData) => {
    this.setState({ password: childData })
  }
  handle_name = (childData) => {
    this.setState({ name: childData })
  }
  handle_phone = (childData) => {
    this.setState({ phone: childData })
  }
  handle_address = (childData) => {
    this.setState({ address: childData })
  }
  change_method = (childData) => {
    if (this.state.pay_type) {
      this.setState({ pay_type: false })
    } else {
      this.setState({ pay_type: true })
    }
  }
  set_localtostate = () => {
    // alert(localStorage.getItem('person'));
    // alert(localStorage.getItem('hours'));
    // let personcunt = localStorage.getItem('person');
    // let hours = localStorage.getItem('hours');
    // this.setState({ personcount: personcunt })
    // this.setState({ hours: hours })
    // console.log(personcunt);
  }
  set_logindetails = () => {
    let user_name = localStorage.getItem('user_name');
    let user_id = localStorage.getItem('user_id');
    this.setState({ user_id: user_id })
    this.setState({ user_name: user_name })
  }
  setCostamount = (childData) => {
    // console.log(childData,'child data')
    this.setState({ price: childData[0].totalAmount })
    this.setState({ offer_price: childData[0].totalAmount })
    this.setState({ personcount: childData[0].employeeCount })
    this.setState({ hours: childData[0].timeCount })
  }
  getpromocode = async () => {

    const getPromo = async (formDataa) => {
      let testurl = `${BASE_URL}` + '/get_promo_code';
      let formData = new FormData();
      let name = 'a250bcr552s'
      formData.append("token", name);
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "type": "formData"
        }
      };
      //  console.log(formData); 
      try {
        let response = await axios.post(testurl, formData);
        // console.log(response.data); 
        return response.data;
      } catch (err) { console.error(err); toast.error('some errror'); return false; }
    }
    let res = await getPromo({ token: 'a250bcr552s' });
    if (res.status) {
      var promocodes
      var promocodes_sec = [
        { value: '', label: 'Select' },
      ];
      // console.log(res.data)
      res.data.map((dataarray, index) => {
        promocodes = [
          ...promocodes_sec,
          { value: dataarray.promo_code, label: dataarray.promo_code }
        ];
        promocodes_sec = promocodes;
        // promocodes_sec.append("value",'test');  
        // console.log(promocodes);
      });
      this.setState({ promocodes: promocodes })
    }
  }


  get_package = async () => {

    const getPAck = async (formDataa) => {
      let testurl = `${BASE_URL}` + '/get_package_detail';
      let formData = new FormData();
      let name = 'a250bcr552s'
      formData.append("token", name);
      formData.append("package_id", localStorage.getItem('pack_id'));

      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "type": "formData"
        }
      };
      //  console.log(formData); 
      try {
        let response = await axios.post(testurl, formData);
        // console.log(response.data); 
        return response.data;
      } catch (err) { console.error(err); toast.error('some errror'); return false; }
    }

    let res = await getPAck({ token: 'a250bcr552s' });

    if (res.status) {
      // console.log(res);
      // this.setState({instructon: childData})
      this.setState({ price: res.data.price })
      this.setState({ offer_price: res.data.price })
      this.setState({ service_name: res.data.package_title })

    } else {
      toast.error(res.message);
    }
  }

  update_cat = async (type) => {
    const updateData = async () => {
      // alert('test6');
      
      let pack_id = localStorage.getItem('pack_id');
      const user_id = localStorage.getItem('user_id');
      let formData = new FormData();
      let token = 'a250bcr552s'
      formData.append("token", token);
      formData.append("hours", this.state.hours);
      formData.append("instruction", this.state.instructon);
      formData.append("employee_id", 1);
      formData.append("package_id", pack_id);
      formData.append("employee_count", this.state.personcount);
      formData.append("final_amount", this.state.offer_price);
      formData.append("date", this.state.booking_date);
      formData.append("promo_code", this.state.apromocode_name);
      formData.append("promo_code_discount", this.state.coupen_price);
      formData.append("times", this.state.booking_time);
      if (type == 1) {
        formData.append("user_id", '0');
        formData.append("guest_phone", this.state.phone);
        formData.append("guest_email", this.state.email);
        formData.append("guest_name", this.state.name);
        formData.append("guest_address", this.state.address);

      } else {
        formData.append("user_id", user_id);
      }


      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "type": "formData"
        }
      };
      // console.log(formData);
      try {
        let response = await axios.post(`${BASE_URL}` + '/add_order', formData);
        if (response.data.status == true) {
        }
        return response.data;
        // sessionStorage.setItem("pageView", response.data);
      } catch (err) { console.error(err); toast.error('some errror'); return false; }
    }
    let res = await updateData();
    if (res.status) {
      console.log(res);
      toast(res.message)
      //  return this.timer = setTimeout(() => window.location.href='home', 5000);
      // setGet_category(res);
      // alert(res.message);
    } else {
      toast.error(res.message);
    }
  }

  verify_promo = async (e) => {
    const verifycode = async () => {
      const getpromoData = async (formDataa) => {
        let testurl = `${BASE_URL}` + '/check_promo_code';
        let formData = new FormData();
        let name = 'a250bcr552s'
        formData.append("token", name);
        formData.append("promo_code", e.value);
        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "type": "formData"
          }
        };
        //  console.log(formData); 
        try {
          let response = await axios.post(testurl, formData);
          return response.data;
        } catch (err) { console.error(err); toast.error('some errror'); return false; }
      }
      let res = await getpromoData({ token: 'a250bcr552s' });
      if (res.status) {
        let pmc = res.data;
        let des;
        // console.log('tets bu shubham ');  

        if (pmc.minimum_order_amount <= this.state.price) {
          des = (pmc.discount * this.state.price) / 100
          if (des > pmc.max_discount_amount) {
            des = pmc.max_discount_amount;
          }

          this.setState({ offer_price: this.state.price - des })
          this.setState({ coupen_price: des })
          this.setState({ apromocode_name: pmc.promo_code })
          this.setState({ promomessage: 'Promo Code Applied' })

        }
        // promessage='test';
        // setGet_category(res);
      } else {
        this.setState({ offer_price: this.state.price })
        this.setState({ coupen_price: '' })
        this.setState({ promomessage: 'Invalid Promocode' })
        // toast.error(res.message);
      }
    }
    verifycode();

  }
  render() {

    // const test =  this.get_package();

    const { nextbuttonstate } = this.state;
    let bookingForm;
    if (nextbuttonstate == 1) {
      bookingForm = <BookingForm1 set_booking_time={this.set_booking_time} set_booking_date={this.set_booking_date} get_package={this.get_package} parentinstCallback={this.handleInstCallback} parentCallback={this.handleCallback} set_localtostate={this.set_localtostate} props={this.state} setCostamount={this.setCostamount} />;
    } else if (nextbuttonstate == 2) {
      bookingForm = <BookingForm2 parentCallback={this.handleCallback} />;
    } else if (nextbuttonstate == 3) {
      bookingForm = <BookingForm3 parentPaybycash={this.parentPaybycash} handle_name={this.handle_name} handle_phone={this.handle_phone} handle_address={this.handle_address} parentCallback={this.handleCallback} add_order={this.update_cat} change_method={this.change_method} verify_promo={this.verify_promo} props={this.state} handle_email={this.handle_email} handle_pass={this.handle_pass} set_logindetails={this.set_logindetails} />;
    }
    //    else if(nextbuttonstate==4) {
    //     bookingForm = <BookingFormPayment parentCallback = {this.handleCallback}/>;

    // }
    return (
      <>
        <div className="container">
          {/* heading */}
          <div className="row">
            <div className="col-md-12">
              <div className='booking-details center-block'>
                <h2>Book your service</h2>
                <h6>Its time to book our cleaning service for your home or apartment.</h6>
              </div>
            </div>
          </div>
          {/* heading end */}


          <div className="row row-deck details-area">

            {bookingForm}



            <div className="col-md-12 col-lg-6 sticky-div">



              <div className="card sticky-div">
                <div className="card-header preference-title">
                  <h4>Booking Summary</h4>
                </div>
                <div className="card-body">
                  <div className='fesilities'>
                    <ul>
                      <li className='mt-2'><i class="fad fa-paint-brush"></i>
                        <p>Service Name: <span class="all-summery-portion">{this.state.service_name}</span></p>
                      </li>
                      {this.state.booking_date ? <li><i class="fad fa-calendar-alt"></i><p>Booked Date:<span class="all-summery-portion">{this.state.booking_date}</span></p></li> : ''}



                      <li><i class="fad fa-user-friends"></i>
                        <p>Number of helpers:<span class="all-summery-portion">{this.state.personcount} helpers</span></p>
                      </li>
                      <li><i class="fad fa-alarm-clock"></i>
                        <p>Select Hours:<span class="all-summery-portion">{this.state.hours} hours</span></p>
                      </li>
                      <li><i class="fad fa-clock"></i>
                        {/* <p>01:00 PM&nbsp;<span>To</span>&nbsp;05:00 PM</p> */}
                        <p>{this.state.booking_time}</p>
                      </li>

                      <li><i class="fa-sync-alt2"></i>
                        <p>One time</p>
                      </li>

                    </ul>
                    <h5>Item Total<span>AED {this.state.price}</span></h5>
                    {this.state.apromocode_name ? <h5>Coupon - ({this.state.apromocode_name})<span className='spanpromo'>you saved {this.state.coupen_price}</span></h5> : ''}
                    <h4>Grand Total<span>AED {this.state.offer_price}</span></h4>
                  </div>

                </div>
              </div>

            </div>





          </div>
        </div>


      </>

    )
  }
}

export default BookingPackage;
