// export const BASE_URL = "http://jama-al-backend.freshpureuae.com/api";
// export const IMAGE_URL = "http://jama-al-backend.freshpureuae.com";


export const BASE_URL = "https://pt-admin-cleaning.prompttechdemohosting.com/api";
export const IMAGE_URL = "https://pt-admin-cleaning.prompttechdemohosting.com/";


// export const BASE_URL = "http://localhost/pt-cleaning-backend/api";
// export const IMAGE_URL = "http://localhost/pt-cleaning-backend";





// export const BASE_URL = "https://iron-gate-backend.prompttechdemohosting.com/admin/api/";

// export const BASE_URL = "https://admin.igexpertuae.com/api/";
// export const IMAGE_URL = "https://admin.igexpertuae.com/";
// export const BASE_URL = "iron_gate/admin/api";


// export const IMAGE_URL = "https://freshpureuae.com/jama-al-backend";

// export const GOOGLEMAPS_API = 'AIzaSyAEysollDCaGfbiPxh-VBvv31u2msCwa1c';

export const public_key = 'pk_live_51KsPWFIJqIMykeIZMvCl3M5yd4bEwvpPfMQ9HTkmL1nlm5fmvIQH2jX0XVAZEcCSICviYCeLmtgZ0K7gujYk8rYa009Vn0no2Oy';
export const secret_key = 'sk_test_51HqJ58APs1IO5yfE3dtoHFXSEfscNwttjD3MFe4Qcu3ir2NhLbrr7HhMhUcYs1NhkVb91LZI9ecYsurUfcslXBDp00NYFzKvjF';


export const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null ;

// export const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null ;

// URL = jama-al-backend.freshpureuae.com

// Prompttech demo

// export const BASE_URL = "http://prompttechdemowork-backend.prompttechdemohosting.com/api";
// export const IMAGE_URL = "http://prompttechdemowork-backend.prompttechdemohosting.com";