import React from "react";
// import "./styles.css";
import "../../innerstyle/payment.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe('pk_test_51KsPWFIJqIMykeIZsafpwqlRHPNo6JpVBzlBHFe6UDXmH4fFgWYuVXuqgU6hUXI36HD3HDVxV5tpvcInTDlyhTex00DbzgUHut');



class paymentForm extends React.Component {
    render() {
// console.log(this.props,"all props a1")
// console.log(this.props.allstate,"all props a2")
        // const App = () => {
            return (
             
                  
                    
                    
                      <Elements stripe={stripePromise}>
                        <CheckoutForm props={this.props} allstatevalue={this.props.allstate} />
                      </Elements>
                   
                  
              
              );
        //   };
    }
}

export default paymentForm;