import React from "react" ;

function Download_app() {
   let url="https://drive.google.com/file/d/16l-eg2oc5J3LxuubQ0IYw9wT8UEf0HFP/view";
   let url2="https://drive.google.com/file/d/16l-eg2oc5J3LxuubQ0IYw9wT8UEf0HFP/view";
    return (
     <>
     <section className='download_section'>
         <div className='container'>
            <div className='row align-items-center'>
               <div className='col-lg-6'>
               <h3>Deep cleaning, sanitization <br /> & much more</h3>
               <h4 className='mb-0'>Download the App Now</h4>
               <div className="button_group">
                 <a href={url} target="_blank">
                  <img src={process.env.PUBLIC_URL + '/assets/images/appstore.png'} /></a>
                  <a href={url2} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/playstore.png'} /></a>
                  </div>
               </div>
               <div className='col-lg-6 text-center img-section'>
               <img src={process.env.PUBLIC_URL + '/assets/images/service2.png'} alt='' />  
               </div>
             </div>
         </div>
      </section>

   </>
    );
  }

  export default Download_app;