import React, { Component } from 'react';

import DatePicker from "../DatePicker";
// import Button from 'react-bootstrap/Button';
import { Button } from 'bootstrap';
import { BASE_URL } from './../../projectString';
import axios from 'axios';
import styles from "../../innerstyle/bookingformcustom.css";
class BookingForm1 extends React.Component {
    constructor() {
        super();
        this.state = {
            employeeCount: 1,
            hourCount: 1
        };
    }
 

    checkHourEmployerRate = async (employeecount,hourscount) => {
        // alert(hourscount);
// console.log(this.state.employeeCount,'Employee count')
        const getData = async (formDataa) => {
          let formData = new FormData();
          let userToken = 'a250bcr552s'
          let employeeCount = employeecount;
          let hourCount=hourscount;
          formData.append("token", userToken);
          formData.append("time_value", hourCount);
          formData.append("employee_count", employeeCount);  
          formData.append("package_id",localStorage.getItem('pack_id'));
          try {
             let response = await axios.post(`${BASE_URL}`+'/get_timehour_calculation',formData);
            localStorage.setItem('totalAmount', Number(response.data.data[0].cost));
            this.props.setCostamount([{ "totalAmount":  Number(response.data.data[0].cost),"employeeCount":employeeCount,"timeCount":hourCount }]);
            // response.data.data[0].cost,'total amount');
            return response.data;
          } catch (err) { console.error(err); 
            // toast.error('some errror');
             return false;
         }
        }
    
        let res = await getData({ token: 'a250bcr552s' });
    
        if (res.status) {
    
        //   setGet_category(res.data);
    
        } else {
        //   toast.error(res.message);
        }
      }






    onTrigger = (event) => {

        this.props.parentCallback(event.target.value);
    }
    oninstruction = (event) => {
        this.props.parentinstCallback(event.target.value);
    }
    set_time = (event) => {
        this.props.set_booking_time(event.target.value);
    }
    UserList = (event) => {
        const personcunt2 = this.state.employeeCount;
        localStorage.setItem('person', Number(personcunt2) + Number(1));
        this.props.set_localtostate()
        this.setState({
            employeeCount: this.state.employeeCount + Number(1)
        });
        // alert(this.state.employeeCount)
        this.checkHourEmployerRate((Number(personcunt2) + Number(1)),this.state.hourCount);
    }

    minUserList = (event) => {
        // let personcunt = localStorage.getItem('person');
        let personcunt = this.state.employeeCount;
        if (personcunt > 1) {
            localStorage.setItem('person', Number(personcunt) - Number(1));
            this.setState({
                employeeCount: Number(personcunt) - Number(1)
            });
            this.props.set_localtostate()
            this.checkHourEmployerRate((Number(personcunt) - Number(1)),this.state.hourCount);
        }
    }
    addhours = (event) => {
        // let personcunt = localStorage.getItem('hours');
        let personcunt = this.state.hourCount
        localStorage.setItem('hours', Number(personcunt) + Number(1));
        this.props.set_localtostate()
        this.setState({
            hourCount: Number(personcunt) + Number(1)
        });
        // Number(personcunt) + Number(1)
        this.checkHourEmployerRate(this.state.employeeCount,(Number(personcunt) + Number(1)));

    }
    minhours = (event) => {
        // let personcunt = localStorage.getItem('hours');
        let personcunt = this.state.hourCount
        if (personcunt > 1) {
            localStorage.setItem('hours', Number(personcunt) - Number(1));
            this.props.set_localtostate()
            this.setState({
                hourCount: Number(personcunt) - Number(1)
            });
            this.checkHourEmployerRate(this.state.employeeCount,(Number(personcunt) - Number(1)));
        }
    }

    render() {
        const selectedDay = val => {
            this.props.set_booking_date(val)
            // console.log(this.props.props);
        };

        //   console.log(this.props.props.personcount);
        return (
            <>
                <div className="col-md-12 col-lg-6">
                    <div className="card">
                        <div className="card-header preference-title">
                            <h4>Cleaning Preferences</h4>
                        </div>
                        <div className='datepicker-div card-area'>
                            <h5 class="card-title left-side">Choose your date</h5>
                            <DatePicker
                                getSelectedDay={selectedDay}
                                color={"#374e8c"}
                                height={"500px"}
                                endDate={100}
                            // selectDate={new Date("2022-04-30")}
                            // labelFormat={"MMMM"}
                            // color={"#374e8c"}  

                            />
                        </div>


                        {/* start */}
                        <div className="employee-time-area mt-4">
                            <p>How many helpers & hours required?</p>

                            <div className="row">
                                <div className="col-md-5 employee-div">
                                    <div className="preference-icon">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/employees-svg.svg'} />
                                        <p>HELPERS(S)</p>
                                    </div>
                                    <div className="preference-quantity">
                                        <div className="employee-counter js-counter">
                                            <div className="counter__item">
                                                <a className="counter__minus js-counter-btn " onClick={this.minUserList} aria-hidden="true" data-action="minus"><i class="far fa-minus-circle" style={{ color: "blue" }}></i></a>
                                            </div>
                                            <div className="counter__item counter__item--center">
                                                <input className="counter-value-input" type="text" value={this.state.employeeCount} disabled="disabled" tabindex="-1" min="0" max="10" required="" />
                                            </div>
                                            <div className="counter__item">
                                                <a className="counter__plus js-counter-btn " onClick={this.UserList} aria-hidden="true" data-action="plus"> <i class="far fa-plus-circle" style={{ color: "blue" }}></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.props.errorPersoncount ? <div className="all-error-clss">{this.props.props.errorPersoncount}</div> : ''}
                                </div>
                                <div className="col-md-5 time-div">
                                    <div className="preference-icon">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/time-svg.svg'} />
                                        <p>HOURS(S)</p>
                                    </div>
                                    <div className="preference-quantity">
                                        <div className="employee-counter js-counter">
                                            <div className="counter__item">
                                                <a className="counter__minus js-counter-btn " onClick={this.minhours} aria-hidden="true" data-action="minus"> <i class="far fa-minus-circle" style={{ color: "blue" }}></i></a>
                                            </div>
                                            <div className="counter__item counter__item--center">
                                                <input className="counter-value-input" type="text" value={this.state.hourCount} disabled="disabled" tabindex="-1" min="0" max="10" required="" />
                                            </div>
                                            <div className="counter__item">
                                                <a className="counter__plus js-counter-btn " onClick={this.addhours} aria-hidden="true" data-action="plus"> <i class="far fa-plus-circle" style={{ color: "blue" }}></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.props.errorHours ? <div className="all-error-clss">{this.props.props.errorHours}</div> : ''}

                                </div>
                            </div>
                        </div>





                        <div className="employee-time-area">
                            <p>Select time</p>
                            <div className="row">

                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="one-time" onClick={this.set_time} value='08:00 AM' name="selector1" required />
                                        <label for="one-time">08:00 AM</label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly" onClick={this.set_time} value='09:00 AM' name="selector1" required />
                                        <label for="weekly" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">09:00 AM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly10" onClick={this.set_time} value='10:00 AM' name="selector1" required />
                                        <label for="weekly10" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">10:00 AM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly12" name="selector1" onClick={this.set_time} value='11:00 AM' required />
                                        <label for="weekly12" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">11:00 AM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly11" onClick={this.set_time} value='12:00 PM' name="selector1" required />
                                        <label for="weekly11" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">12:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly2" onClick={this.set_time} value='01:00 PM' name="selector1" required />
                                        <label for="weekly2" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">01:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly3" name="selector1" onClick={this.set_time} value='02:00 PM' required />
                                        <label for="weekly3" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">02:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly4" name="selector1" onClick={this.set_time} value='03:00 PM' required />
                                        <label for="weekly4" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">03:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly5" name="selector1" required onClick={this.set_time} value='04:00 PM' />
                                        <label for="weekly5" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">04:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly6" name="selector1" required onClick={this.set_time} value='05:00 PM' />
                                        <label for="weekly6" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">05:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly7" name="selector1" required onClick={this.set_time} value='06:00 PM' />
                                        <label for="weekly7" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">06:00 PM</label>

                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-3 sm-box1">
                                    <div className="styled-radio styled-radio2">
                                        <input type="radio" id="weekly8" name="selector1" required onClick={this.set_time} value='07:00 PM' />
                                        <label for="weekly8" class="radio-offer" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="SAVE UP TO 15%">07:00 PM</label>

                                    </div>
                                </div>
                            </div>
                            {this.props.props.errorBookingTime ? <div className="all-error-clss">{this.props.props.errorBookingTime}</div> : ''}

                        </div>

                        {/* end */}

                        <div className="preference-radio mt-4">

                            <p>Do you have any special requerments ? <span className="optional-fade">(optional)</span></p>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea onChange={this.oninstruction} required className="optinal-textarea"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button type="button" value="3" onClick={this.onTrigger} data-action="toggle" data-ref="cleaning-step-2" class="btn btn-success d-block mb-2 w-100 next-button-tracker">Next</button>
                        </div>






                    </div>
                </div>
            </>

        )
    }
}

export default BookingForm1;