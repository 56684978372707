import { ConstructionOutlined } from '@mui/icons-material';
import React, { Component } from 'react'


export default class featuredPayment extends Component {

    constructor(props){
        super(props);
        this.state = {
            payment: 'account',
            stripePromise: '',
        }
    }

    eventChange = (e) => {
        console.log(e.target.value,'payment method')
        this.setState({
            payment: e.target.value,
        }, () => {
            
            this.props.paymentMethod(this.state.payment);
        });
    }
    
    render() {
        const paymentMethod = this.state.payment;
        // let currency = localStorage.getItem('currency');

        // let amount = localStorage.getItem('newAmount');
        return (
            
            // <>

                        <div className="card-body guest-login-confirm-div">
                            <h5 className="card-title card-title-booking-form-3">Select payment method</h5>
                            <div className='internal-wrapper-div'>
                                <div class="form-check form-switch">
                                        <input type="radio" onChange={(e) => this.eventChange(e)} name="payment" value="account" class="custom-control-input" id="Account" checked={this.state.payment === 'account' ? 'checked':''} />
                                    {/* <input className="form-check-input" onChange={(e) => this.eventChange(e)} type="checkbox" id="flexSwitchCheckChecked" /> */}
                                    <label className="form-check-label" for="Account">Pay by cash</label>
                                </div>
                                <div class="form-check form-switch">
                                    {/* <input className="form-check-input" onChange={(e) => this.eventChange(e)} type="checkbox" id="flexSwitchCheckChecked" /> */}
                                    <input type="radio" onChange={(e) => this.eventChange(e)} name="payment" value="stripe" class="custom-control-input" id="Stripe" checked={this.state.payment === 'stripe' ? 'checked':''} />
                                    <label className="form-check-label" for="Stripe">Pay by card</label>
                                </div>
                            </div>
                        </div>

              
               
            // </>
        )
    }
}
