import React, { Component } from 'react';
import styles from "../../innerstyle/bookingformcustom.css";
import loaderstyle from "../../innerstyle/loader.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select';
import { loadStripe } from '@stripe/stripe-js';
import { BASE_URL } from './../../projectString';
import StripeCheckout from "react-stripe-checkout";
import FeaturedPayment from './featuredPayment.js';
import InjectedCheckoutForm from './paymentForm';
import axios from 'axios';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const stripePromise = loadStripe('pk_test_51KsPWFIJqIMykeIZsafpwqlRHPNo6JpVBzlBHFe6UDXmH4fFgWYuVXuqgU6hUXI36HD3HDVxV5tpvcInTDlyhTex00DbzgUHut');
// import Stripe from 'stripe';




class BookingForm3 extends React.Component {
    constructor() {
        super();
        this.state = {
            key: 0,
            errorUsername: '',
            errorPassword: '',
            usernameValue: '',
            passwordValue: '',
            gaddressValue: '',
            errorGaddress: '',
            gphoneValue: '',
            errorGphone: '',
            gnameValue: '',
            errorGname: '',
            paymentMethod: 'account',
            paymentIdGet: '',
            pageLoader:false,
            payment: 'account',
            stripePromise: ''
        };
        localStorage.setItem('logintype',this.state.key);
    }
setLoader =()=>{
    this.setState({
        pageLoader:true
    });
}
hideLoader =()=>{
    setTimeout(() => {
        this.setState({
            pageLoader:false
        });
   }, 1000)
   
}


// name
// email
// product_name
// price
// user_id
// date
// starting_hours
// employee_count
// instruction


    login_user = async () => {
        this.setLoader();
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let formData = new FormData();
        let errorFlag = 0;
        this.setState({
            errorUsername: '',
            errorPassword: '',
        });
        if (this.state.usernameValue === null || this.state.usernameValue == '') {
            this.setState({
                errorUsername: 'Please enter email address',

            });

        } else {
            if (!this.state.usernameValue || regex.test(this.state.usernameValue) === false) {
                this.setState({
                    errorUsername: "Please enter valid email address"
                });
                errorFlag = 1;
            }
        }
        if (this.state.passwordValue === null || this.state.passwordValue === '') {

            this.setState({
                errorPassword: 'Please enter password',

            });
            errorFlag = 1;
        }

        if (errorFlag == 0) {
            let logtoken = 'a250bcr552s'
            formData.append("token", logtoken);
            formData.append("email", this.state.usernameValue);
            formData.append("password", this.state.passwordValue);
            const options = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    "type": "formData"
                }
            };

            try {
                let response = await axios.post(`${BASE_URL}` + '/login', formData);
                if (response.data.status == true) {
                    let user_data = response.data.data
                    // console.log(user_data);
                    localStorage.setItem('user_id', user_data.user_id);
                    localStorage.setItem('user_contact', user_data.user_contact);
                    localStorage.setItem('user_email', user_data.user_email);
                    localStorage.setItem('user_name', user_data.user_name);
                    localStorage.setItem('address', user_data.address);
                    this.props.set_logindetails();
                    Swal.fire({
                        position: 'center',
                        type: 'success',
                        title: 'Succesfully logged in',
                        showConfirmButton: false,
                        timer: 2500
                      })
                   
                    this.hideLoader();
                } else {
                    this.setState({
                        errorPassword: response.data.message,

                    });
                    Swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Error',
                        text: 'Please check the login details',
                        showConfirmButton: false,
                        timer: 1000
                      })
                   
                    this.hideLoader();
                }
                
            } catch (err) {
                Swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'Error',
                    text: 'Sorry something went wrong try again',
                    showConfirmButton: false,
                    timer: 2000
                  })
                return false;
            }
        }else{
            // setTimeout(function(){
                this.hideLoader();
            //   }, 200);
              
        }
    }


    handleToken = (token) => {

        const checkpayment = async (formDataa) => {
            let testurl = 'https://cws.in.net/iron_gate/payment/';
            let formData = new FormData();
            formData.append("stripeToken", token.id);
            //  formData.append("cvc",token.);
            formData.append("name", 'testname');
            formData.append("email", this.props.props.email);
            formData.append("amount", this.props.props.offer_price);
            formData.append("exp_month", token.exp_month);
            const options = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    "type": "formData"
                }
            };
            try {
                let paymentresponse = await axios.post(testurl, formData);
                this.props.add_order(2);
                console.log(paymentresponse);
                // return   response.data;
            } catch (err) { return false; }
        }
        checkpayment();
    }

    onTrigger = (event) => {
        this.props.parentCallback(event.target.value);
    }

    payBycash = (event) => {
        event.preventDefault();
        this.login_user();
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        let errorFlag = 0;
        this.setState({
            errorGaddress: '',
            errorGphone: '',
            errorGname: '',
            errorUsername: ''
        });

        if (this.state.key == 0) {

            if (this.state.usernameValue === null || this.state.usernameValue == '') {
                this.setState({
                    errorUsername: 'Please enter email address',

                });

            } else {
                if (!this.state.usernameValue || regex.test(this.state.usernameValue) === false) {
                    this.setState({
                        errorUsername: "Please enter valid email address"
                    });
                    errorFlag = 1;
                }
            }


            if (this.state.gphoneValue === null || this.state.gphoneValue == '') {
                this.setState({
                    errorGphone: 'Please enter your phone number',

                });
                errorFlag = 1;
            }
            else{

                if(isNaN(this.state.gphoneValue) || this.state.gphoneValue.length < 9){

                    this.setState({
                        errorGphone: 'Please enter a valid phone number',
    
                    });
                    errorFlag = 1;
                }
            }

            if (this.state.gaddressValue === null || this.state.gaddressValue == '') {
                this.setState({
                    errorGaddress: 'Please enter your address',

                });
                errorFlag = 1;
            }
            if (this.state.gnameValue === null || this.state.gnameValue === '') {

                this.setState({
                    errorGname: 'Please enter your name',

                });
                errorFlag = 1;
            }

            let newState = this.state;

            if((newState.usernameValue != null || newState.usernameValue != '') && (newState.gphoneValue != null || newState.gphoneValue != '') && (newState.gaddressValue != null || newState.gaddressValue != '') && (newState.gnameValue != null || newState.gnameValue != '')){

                if(regex.test(newState.usernameValue) == true && !isNaN(newState.gphoneValue) && newState.gphoneValue.length >= 9){
                    
                    let formData = new FormData();
                    let userToken = 'a250bcr552s'
                    let name = newState.gnameValue;
                    let email = newState.usernameValue;
                    let product_name = 'cleaning service';
                    let price = this.props.props.offer_price;
                    let address = newState.gaddressValue;
                    let phoneNumber = newState.gphoneValue;
                    let user_id = (localStorage.getItem('user_id')) ? localStorage.getItem('user_id') : 0 ;
                    let date = (localStorage.getItem('date')!="")?localStorage.getItem('date'):"";
                    let starting_hours = (localStorage.getItem('starting_hours')!="")?localStorage.getItem('starting_hours'):"";
                    let person = (localStorage.getItem('person')!="")?localStorage.getItem('person'):"";
                    let instruction = (localStorage.getItem('instruction')!="")?localStorage.getItem('instruction'):"";
                    let hours = (localStorage.getItem('hours')!="")?localStorage.getItem('hours'):"";

                    formData.append("token", userToken);
                    formData.append("name", name);
                    formData.append("email", email);
                    formData.append("package_id", localStorage.getItem('pack_id'));
                    formData.append("product_name", product_name);
                    formData.append("price", price);
                    formData.append("phone_number", phoneNumber);
                    formData.append("address", address);
                    formData.append("user_id", user_id);
                    formData.append("date", date);
                    formData.append("starting_hours", starting_hours);
                    formData.append("employee_count", person);
                    formData.append("instruction",instruction);
                    formData.append("hours",hours);
// alert();
                    axios({
                        url: `${BASE_URL}/Cashpayment_api`,
                        method: 'POST',
                        data: formData,
                    }).then(response => {
    
    // alert(response.data.message.status,"success")
                        if (response.data.message.status == 200) {
                            // console.log('reaching here')

                            Swal.fire({
                                position: 'center',
                                type: 'success',
                                title: response.data.message.message,
                                showConfirmButton: true,
                                timer: 3500
                            })
                              setTimeout(function(){
                                document.location.href="/";
                            }, 3500);
                            
                        } else {
                            // alert("success2")
                            Swal.fire({
                                position: 'center',
                                type: 'success',
                                title: response.data.message.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
    
                        }
                        // console.log(response,"success value")
                    }).catch((error) => {
                        // alert(error,"Error")
                        Swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Sorry something went wrong',
                            showConfirmButton: false,
                            timer: 1500
                        })
    
    
                    });

                }
            }

        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Please login to continue',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            this.login_user();
        }


    }



    selecttype = (event) => {
        // console.log(this.state.key, "login type");
        localStorage.setItem('logintype',this.state.key);
    }

    onSubmit = (event) => {
        this.props.add_order(1);
    }
    handleChange = (event) => {
        console.log(event.target.value, "checkbox value")
    }

 
    handle_email = (event) => {
        this.setState({
            usernameValue: event.target.value
        });
        localStorage.setItem('emailwithoutlogin', this.state.usernameValue);
        this.setState({
            errorUsername: '',

        });
        // this.props.handle_email(event.target.value);
    }
    handle_pass = (event) => {
        this.setState({
            passwordValue: event.target.value
        });
        this.setState({

            errorPassword: '',
        });
    }
    handle_address = (event) => {
        this.setState({
            gaddressValue: event.target.value
        });
        localStorage.setItem('addresswithoutlogin',this.state.gaddressValue);
        this.setState({

            errorGaddress: '',
        });
    }
    handle_phone = (event) => {
        this.setState({
            gphoneValue: event.target.value
        });
        localStorage.setItem('phonewithoutlogin',this.state.gphoneValue);
        this.setState({

            errorGphone: '',
        });
    }
    handle_name = (event) => {
        // this.props.handle_name(event.target.value);
        this.setState({
            gnameValue: event.target.value
        });
        localStorage.setItem('namewithoutlogin',this.state.gnameValue);
        this.setState({
            errorGname: '',

        });
    }


    paymentIdGet = (paymentId) => {

        this.setState({
            paymentId: paymentId,
        });
        console.log(paymentId,'payment details')
    }

    handleSubmit = (e) => {

        if(!this.props.props.user_id){

            this.setState({
                gnameValue: e.target.full_name.value,
                usernameValue: e.target.email.value,
                gphoneValue: e.target.phone.value,
                gaddressValue: e.target.instructions_to_seller.value,
            }, () => {
                this.payBycash();
            });
        }
        else{

            this.setState({
                gnameValue: localStorage.getItem('user_name'),
                usernameValue: localStorage.getItem('user_email'),
                gphoneValue: localStorage.getItem('user_contact'),
                gaddressValue: localStorage.getItem('address'),
            }, () => {
                this.payBycash();
            });
        }
    }


    paymentMethod = (e) => {
        // console.log(e.target.value,'payment method')
       
        
        this.setState({
            paymentMethod: e.target.value,
        }, () => {
            
            // this.props.paymentMethod(this.state.payment);
        });
    }

    setLoaderActive=()=>{
        this.setState({
            pageLoader:true
        });
    }

    setLoaderInactive=()=>{
        this.setState({
            pageLoader:false
        });
    }



    render() {
        const selectedDay = val => {
            console.log(val, "datevalue");
        };
      
        return (
            <>
                <style type="text/css">
                    {`
                    .btn-flat {
                    background-color: purple;
                    color: white;
                    }

                    .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
                    .accordion{
                        margin-top:20px;
                    }
                    `}
                </style>
                
                {this.state.pageLoader ?<div class="loading">Loading&#8230;</div>:''}
                <div className="col-md-12 col-lg-6">
                    <div className="card">
                        <div className="card-header preference-title">
                            <h4>{!this.props.props.user_id ? 'Enter your details' : 'Customer details'}</h4>
                        </div>
                        {/* <div className='guest-login-confirm-div'>
                            <div className="form-label">How long do you need babysitter/nanny to stay?</div>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={value}
                                    onChange={this.handleChange}
                                >
                                    <FormControlLabel value="guest" control={<Radio />} label="Book as a guest" />
                                    <FormControlLabel value="login" control={<Radio />} label="Login" />
                                </RadioGroup>
                            </FormControl>
                        </div> */}
                        <div className='tabs_style'>
                            {!this.props.props.user_id ? (<div className='logsin' style={{ padding: "15px" }}>
                                <Tabs activeKey={this.state.key}
                                    onSelect={key => this.setState({ key })}>
                                    <TabList>
                                        <Tab>
                                            <label for="guests" eventKey="guest" onClick={this.selecttype} className='labelstyle'>&nbsp;Book as a guest</label></Tab>

                                        <Tab>
                                            <label for="logins" eventKey="login" onClick={this.selecttype} className='labelstyle'>&nbsp;Login</label>
                                        </Tab>
                                    </TabList>

                                    <TabPanel>
                                        <form id="userDetails" onSubmit={this.handleSubmit} >
                                            <div className="card-body p-0 mt-4">
                                                <h5 className="card-title card-title-booking-form-3">Enter the details</h5>
                                                <div className="form-group full-name-group">
                                                    <label className="form-label">Your name</label>
                                                    <input type="text" autoComplete='off' name="full_name" onChange={this.handle_name} value={this.state.gnameValue} data-rules="required|min:3" placeholder="Enter your full name" className="form-control"  id="nameWithoutlogin"/>
                                                    {this.state.errorGname ? <div className="all-error-clss">{this.state.errorGname}</div> : ''}
                                                </div>
                                                <div className="form-group full-name-group">
                                                    <label className="form-label">Your email</label>
                                                    <input type="text" autoComplete='off'  name="email" onChange={this.handle_email} value={this.state.usernameValue} data-rules="required|min:3" placeholder="Enter your email address" className="form-control" id="emailWithoutlogin"/>
                                                    {this.state.errorUsername ? <div className="all-error-clss">{this.state.errorUsername}</div> : ''}
                                                </div>
                                                <div className="form-group full-name-group">
                                                    <label className="form-label">Your phone</label>
                                                    <input type="text" autoComplete='off' name="phone" onChange={this.handle_phone} value={this.state.gphoneValue} data-rules="required|min:3" placeholder="Enter your phone number" className="form-control"  id="phoneWithoutlogin" />
                                                    {this.state.errorGphone ? <div className="all-error-clss">{this.state.errorGphone}</div> : ''}
                                                </div>
                                                <div className="form-group full-name-group">
                                                    <label className="form-label">Your address</label>
                                                    <textarea autoComplete='off' name="instructions_to_seller" onChange={this.handle_address} value={this.state.gaddressValue} rows="3" placeholder="Enter your address here." className="form-control border border-secondary"  id="addressWithoutlogin"></textarea>
                                                    {this.state.errorGaddress ? <div className="all-error-clss">{this.state.errorGaddress}</div> : ''}
                                                </div>
                                            </div>
                                        </form>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="card-body p-0 mt-4">
                                            <h5 className="card-title card-title-booking-form-3">Enter the details</h5>

                                            <div className="form-group full-name-group mb-3">
                                                <label className="form-label">Enter your emaill</label>
                                                <input type="text" name="email_address" onChange={this.handle_email} value={this.state.usernameValue} placeholder="Enter your email address" className="form-control" />
                                                {this.state.errorUsername ? <div className="all-error-clss">{this.state.errorUsername}</div> : ''}
                                            </div>
                                            <div className="form-group full-name-group">
                                                <label className="form-label">Enter your password</label>
                                                <input type="password" name="password" onChange={this.handle_pass} value={this.props.props.handle_pass} placeholder="Enter your password" className="form-control" />
                                                {this.state.errorPassword ? <div className="all-error-clss">{this.state.errorPassword}</div> : ''}
                                            </div>
                                            <div className="form-group full-name-group mt-4 text-center">
                                                <button type="button" onClick={this.login_user} className="w-btn w-btn-blue w-btn-blue-header btnpay btnset12">Login</button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>) : (
                                <div className="container mt-4 mb-4 p-3 d-flex justify-content-center">
                                    <div className="card p-4">
                                        <div className="image d-flex flex-column justify-content-center align-items-center">
                                            <button type="button" className="btn btn-secondary">
                                                <img src="https://i.imgur.com/wvxPV9S.png" height="100" width="100" />
                                            </button>
                                            <span className="name mt-3">{localStorage.getItem('user_name')}</span> <span className="idd">{localStorage.getItem('user_email')}</span>
                                            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                                <span className="idd1">{localStorage.getItem('user_contact')}</span> <span><i className="fad fa-phone"></i></span>
                                            </div>
                                            <div className="d-flex flex-row justify-content-center align-items-center mt-3"> <span className="number"> <span className="follow">{localStorage.getItem('address')}</span></span> </div>


                                        </div>
                                    </div>
                                    <form id="userLogedDetails" onSubmit={this.handleSubmit}>
                                        
                                    </form>
                                </div>
                            )
                            }
                        </div>








                        <div className="card-body guest-login-confirm-div">
                            <h5 className="card-title card-title-booking-form-3">Select payment method</h5>
                            <div className='internal-wrapper-div'>
                                <div class="form-check form-switch">
                                        <input type="radio" onChange={(e) => this.paymentMethod(e)} name="payment" value="account" class="custom-control-input" id="Account" checked={this.state.paymentMethod === 'account' ? 'checked':''} />
                                    {/* <input className="form-check-input" onChange={(e) => this.eventChange(e)} type="checkbox" id="flexSwitchCheckChecked" /> */}
                                    <label className="form-check-label" for="Account">Pay by cash</label>
                                </div>
                                <div class="form-check form-switch">
                                 
                                    <input type="radio"  name="payment" value="stripe" class="custom-control-input" id="Stripe" checked={this.state.paymentMethod === 'stripe' ? 'checked':''} />
                                    <label className="form-check-label" for="Stripe">Pay by card</label>
                                </div>
                            </div>
                        </div>    

                        {/* <FeaturedPayment paymentMethod={this.paymentMethod} /> */}

                        {this.state.paymentMethod === 'stripe' ? <Elements stripe={stripePromise}>
                        <CheckoutForm setLoaderInactive={this.props.setLoaderInactive} setLoaderActive={this.props.setLoaderActive} props={this.props} allstatevalue={this.props.allstate} />
                      </Elements> : (<div class="card-footer"><button  onClick={this.payBycash} form={!this.props.props.user_id ? "userDetails" : "userLogedDetails"} type="button" value="4" data-ref="cleaning-step-2" class="btn btn-success d-block mb-2 w-100 next-button-tracker">Confirm Booking</button></div>)}



                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col text-left">
                                <button value="1" onClick={this.onTrigger} type="button" data-action="toggle" data-ref="cleaning-step-1" class="btn btn-primary btn-action back-button-tracker">Back</button>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default BookingForm3;