import React from 'react';

function Terms() {
return (
<>
<section className="hero__banner p-relative d-flex align-items-center"> 
  <div className="container">
     <div className="row align-items-center">
        <div className="col-lg-12 mt-70">
           <div className="hero__content pr-80 text-center">
            <h1 className='text-white d_text'>Terms And Conditions</h1>
            <h1 className='text-white m_text'>Terms</h1>
           </div>
        </div>       
     </div>
  </div>
</section>

<section className="pb-45 pt-45 p-relative border-style">
            <div className="container">
            <div className="row">
                  <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                     <div className="section__title-wrapper section__title-wrapper-5 text-center mb-25">
                        <h2 className="section__title-5 section__title-5-p mb-15">Terms And Conditions</h2>
                        <p>General Terms & Conditions</p>
                      </div>
                  </div>
               </div>

               <div className='row'>
               <div className='col-lg-12'>
                   <div className='contentp'>
                       {/* <h5>What is Lorem Ipsum?</h5> */}
                       <p>The following are the terms of use that govern use of the Site ("Terms of Use"). By using the Site you expressly agree to be bound by these Terms of Use and the cleaningservice.com privacy policy and to follow these Terms of Use and all applicable laws and regulations governing use of the Site. cleaningservice CLEANING SERVICE LLC reserves the right to change these Terms of Use at any time, effective immediately upon posting on the Site. Please check this page of the Site periodically. We will note when there are updates to the Terms of Use at the bottom of the Terms of Use. If you violate these Terms of Use, cleaningservice CLEANING SERVICE LLC may terminate your use of the Site, bar you from future use of the Site, and/or take appropriate legal action against you.</p>
                   </div>

                   <div className='contentp'>
                       <h5>Refunds & Cancellation Policy For Services</h5>
                       <p>cleaningservice.com offers and orders from local merchants and service providers. All the deals and orders bought at cleaningservice.com are refundable before the delivery of the service.</p>
                       <p>cleaningservice.com is not responsible for the quality of the service provided by the merchants and service providers.</p>
                       <p>However, in exceptional cases and if a refund is the only option, cleaningservice CLEANING SERVICE LLC reserves the right to offer all monies as cleaningservice CLEANING SERVICE LLC credit.</p> 
                  
                   </div>

                   <div className='contentp'>
                       <h5>Please note that refunds are not applicable in the following cases:</h5>
                       <p>If you don't show up for your sechaduled appointment without informing the merchant.</p>
                       <p>If your order has expired</p>
                       <p>If you change your plans</p>
                       <p>If you accidentally purchase a order</p>
                       <p>For personal reasons or you change your mind</p>
                       <p>Appointments Cancellation and Rescheduling Policy</p>
                       <p>“No Show” shall mean any buyer who fails to arrive for a scheduled appointment. “Same Day Cancellation” shall mean any buyer who cancels an appointment less than 24 hours before their scheduled appointment.</p>
                       
                   </div>

                  

               </div>
               </div>

              
            </div>
</section>
</>
);
}

export default Terms;
