import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { BASE_URL } from './../../projectString';
import axios from 'axios';
import CardSection from "./CardSection";
import Swal from 'sweetalert2';
import { ContactlessOutlined } from "@mui/icons-material";
class CheckoutForm extends React.Component {
    constructor() {
        super();
        this.state = {
            pageLoader: false
        };
    }

    setLoader = () => {
        this.setState({
            pageLoader: true
        });
    }



    payBycash = (nameVal,addressVal,phoneVal,emailVal) => {
        this.setState({
            pageLoader:true
        });
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.setState({
            errorGaddress: '',
            errorGphone: '',
            errorGname: '',
            errorUsername: ''
        });
        let errorFlag = 0;
        // if (this.state.key == 0) {
            if (nameVal === null || nameVal === '') {

                Swal.fire({
                    title: 'Error!',
                    text: 'Please enter your name',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                this.setState({
                    pageLoader:false
                });
                errorFlag = 1;
               
            }
            if (emailVal === null || emailVal == '') {
              
                Swal.fire({
                    title: 'Error!',
                    text: 'Please enter email address',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                this.setState({
                    pageLoader:false
                });
                errorFlag = 1;
               
            } else {
                 if (!emailVal || regex.test(emailVal) === false) {
               
                Swal.fire({
                    title: 'Error!',
                    text: 'Please enter valid email address',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                this.setState({
                    pageLoader:false
                });
                errorFlag = 1;
              
                }
               
            }

           
            if (phoneVal === null || phoneVal == '') {
                // this.setState({
                //     errorGphone: 'Please enter your phone number',

                // });

                
                Swal.fire({
                    title: 'Error!',
                    text: 'Please enter your phone number',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                this.setState({
                    pageLoader:false
                });
                errorFlag = 1;
               
            }
            else{

                if(isNaN(phoneVal) || phoneVal < 9){

                    // this.setState({
                    //     errorGphone: 'Please enter a valid phone number',
    
                    // });
                    errorFlag = 1;
                    Swal.fire({
                        title: 'Error!',
                        text: 'Please enter a valid phone number',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                    this.setState({
                        pageLoader:false
                    });
                    errorFlag = 1;
                   
                }
            }
       
            if (addressVal === null || addressVal == '') {
                this.setState({
                    errorGaddress: 'Please enter your address',

                });
                this.setState({
                    pageLoader:false
                });
                errorFlag = 1;
                
            }
         

            let newState = this.state;
           
            if(errorFlag==0){
                return true;
            }
           
            else {
            
                return false;
            
            }


    }

    handleSubmit = async event => {
        let errorCode=1;
        const nameVal = document.getElementById('nameWithoutlogin').value
        const addressVal = document.getElementById('addressWithoutlogin').value
        const phoneVal = document.getElementById('phoneWithoutlogin').value
         const emailVal = document.getElementById('emailWithoutlogin').value
        if(localStorage.getItem('logintype')==0){ //login as a guest go for validation
            
            if(this.payBycash(nameVal,addressVal,phoneVal,emailVal)){
             errorCode=0;
             
            }
        }else{

        }
        event.preventDefault();

        if(errorCode==0){
        const { stripe, elements } = this.props;
        this.setState({
            pageLoader:true
        });

        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);

        stripe.createToken(cardElement, { 'currency': 'AED' }).then(function (result) {
            
           
           
            // console.log(this.props.logintype,'all state values reached')
            if (result.token.id) {
                // console.log(this.props,'offer price')
              
                // console.log(localStorage.getItem('logintype'),'local storage login type')
                let formData = new FormData();
                let userToken = 'a250bcr552s'
                let stripeToken = result.token.id;
                let name = nameVal!=""?nameVal:"";
                let email = emailVal!=""?emailVal:"";
                let product_name = 'cleaning service';
                let price = (localStorage.getItem('totalAmount')!="")?localStorage.getItem('totalAmount'):"";
                let address= addressVal!=""?addressVal:"";
                let phoneNumber= localStorage.getItem('user_contact') != "" ? localStorage.getItem('user_contact') :  (phoneVal!="")?phoneVal:"";
                let user_id=(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):0;
                let date=(localStorage.getItem('date')!="")?localStorage.getItem('date'):"";
                let starting_hours=(localStorage.getItem('starting_hours')!="")?localStorage.getItem('starting_hours'):"";
                let person=(localStorage.getItem('person')!="")?localStorage.getItem('person'):"";
                let instruction=(localStorage.getItem('instruction')!="")?localStorage.getItem('instruction'):"";
                let hours=(localStorage.getItem('hours')!="")?localStorage.getItem('hours'):"";
                console.log(localStorage.getItem('user_name'),"nameVal");

                formData.append("token", userToken);
                formData.append("stripeToken", stripeToken);
                formData.append("name", name);
                formData.append("email", email);
                formData.append("package_id", localStorage.getItem('pack_id'));
                formData.append("product_name", product_name);
                formData.append("price", price);
                formData.append("phone_number", phoneNumber);
                formData.append("address", address);
                formData.append("user_id", user_id);
                formData.append("date", date);
                formData.append("starting_hours", starting_hours);
                formData.append("employee_count", person);
                formData.append("instruction",instruction);
                formData.append("hours",hours);

                axios({
                    url: `${BASE_URL}/Stripe_payment`,
                    method: 'POST',
                    data: formData,
                }).then(response => {


                    if (response.data.message.status == 200) {
                        console.log('reaching here')
                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: response.data.message.message,
                            showConfirmButton: false,
                            timer: 3500
                        })
                        setTimeout(function(){
                            document.location.href="/";
                        }, 3500);
                      
                    } else {
                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: response.data.message.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                      
                    }
                    // console.log(response,"success value")
                }).catch((error) => {

                    Swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Sorry something went wrong',
                        showConfirmButton: false,
                        timer: 1500
                    });
                   
                });

            } else {
                Swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'Sorry something went wrong',
                    showConfirmButton: false,
                    timer: 1500

                });
                // this.hideLoader();
               
            }
        });
        setTimeout(() => {
            this.setState({
                pageLoader:false
            });
       }, 4000)
    }
    };


    
    
        
    



    render() {
        // console.log(this.props.logintype,'all state values reached2')
        // console.log(this.props.allstatevalue,'all state values reached')
        return (

            <div class="card-body guest-login-confirm-div">
                {this.state.pageLoader ? <div class="loading">Loading&#8230;</div> : ''}
                {/* <div class="product-info">
          <h3 className="product-title">Apple MacBook Pro</h3>
          <h4 className="product-price">$999</h4>
        </div> */}
                <form onSubmit={this.handleSubmit}>
                    <CardSection />
                    <button disabled={!this.props.stripe} className="btn-pay">
                        Book Now
                    </button>
                </form>
            </div>
        );
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}