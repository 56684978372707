import React, { Component } from 'react';

// import styles from "./innerstyle/bookingformcustom.css";
import styles from "../../innerstyle/bookingformcustom.css";
class BookingForm2 extends React.Component {
    onTrigger = (event) => {

        this.props.parentCallback(event.target.value);

    }

    render() {
        const selectedDay = val => {
            console.log(val, "datevalue");
        };

        return (
            <>
                <div className="col-md-12 col-lg-6">
                    <div className="card">
                        <div className="card-header preference-title">
                            <h4>Additional details</h4>
                        </div>





                        <h1>some dynamic form comes here</h1>





                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col text-left">
                                <button value="1" onClick={this.onTrigger} type="button" data-action="toggle" data-ref="cleaning-step-1" class="btn btn-primary btn-action back-button-tracker">Back</button>
                            </div>
                            <div class="col text-right">
                                <button value="3" onClick={this.onTrigger} type="button" data-action="submit" class="btn btn-success btn-action next-button-tracker">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default BookingForm2;