
import React from 'react';

function Privacy() {
return (
<>

<section className="hero__banner p-relative d-flex align-items-center"> 
  <div className="container">
     <div className="row align-items-center">
        <div className="col-lg-12 mt-70">
           <div className="hero__content pr-80 text-center">
            <h1 className='text-white'>Privacy Policy</h1>
           </div>
        </div>       
     </div>
  </div>
</section>

<section className="pb-45 pt-45 p-relative border-style">
            <div className="container">
            <div className="row">
                  <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                     <div className="section__title-wrapper section__title-wrapper-5 text-center mb-25">
                        <h2 className="section__title-5 section__title-5-p mb-15">Privacy Policy</h2>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p> */}

                      </div>
                  </div>
               </div>
<div className='row'>
 <div className='col-lg-12'>
<div className='contentp'>




    <h5>Privacy Policy</h5>
   <p>INFORMATION GATHERED BY cleaningservice.COM. This is cleaningservice.COM’s (“cleaningservice.COM”) online privacy policy (“Policy”). This policy applies only to activities cleaningservice.COM engages in on its website and does not apply to cleaningservice.COM activities that are "offline" or unrelated to the website.</p>

<p>cleaningservice.COM collects certain anonymous data regarding the usage of the website. This information does not personally identify users, by itself or in combination with other information, and is gathered to improve the performance of the website. The anonymous data collected by the cleaningservice.COM website can include information such as the type of browser you are using, and the length of the visit to the website. You may also be asked to provide personally identifiable information on the cleaningservice.COM website, which may include your name, address, telephone number and e-mail address. This information can be gathered when feedback or e-mails are sent to cleaningservice.COM, when you register for services, or make purchases via the website. In all such cases you have the option of providing us with personally identifiable information.</p>
</div>

{/* <div className='contentp'>
    <h5>Where does it come from?</h5>
    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.   </p>
</div>

<div className='contentp'>
    <h5>Why do we use it?</h5>
    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
</div>

<div className='contentp'>
    <h5>Where can I get some?</h5>
    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
</div> */}

</div>
</div>
</div>
</section>

</>
);
}

export default Privacy;
