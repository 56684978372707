import React from "react";
class CommingSoon extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        brand: "Ford",
        model: "Mustang",
        color: "red",
        year: 1964
      };
    }
    changeColor = () => {
      this.setState({color: "blue"});
    }
    render() {
      return (
        <div className="comming-soon" style={{textAlign:"center"}}>
          <h1 style={{color:"#05d4fa"}}>Coming Soon..</h1>
          <p>
            We will make this service available soon...
          </p>
         
        </div>
      );
    }
  }
  export default CommingSoon